import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import '../css/home.scss';
import { Navbar } from '../components/navbar';
import Terms from "../components/terms/terms";
import "../css/terms.scss";

const IndexPage = ({ data }) => (
  <>
    <Layout>
      <SEO title="Terms" />
      <div className="terms-page">
        <Navbar />
        <div className="page-content">
            <Terms />
        </div>
        <div className="footer-section">
          <MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query TermsQuery {
    Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
      nodes {
        body
      }
    }
    Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
      nodes {
        body
      }
    }
  }
`;
export default IndexPage;
